var templateObject = Object.freeze(["\n              query($VGI: String) {\n                flow(\n                  where: {\n                    _and: [\n                      { version_group_id: { _eq: $VGI } }\n                      { archived: { _eq: false } }\n                    ]\n                  }\n                ) {\n                  id\n                  name\n                }\n              }\n            "]);
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ManagementLayout',{attrs:{"show":"","control-show":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Cloud Hooks")]},proxy:true},{key:"subtitle",fn:function(){return [(!_vm.hasPermission('update', 'cloud-hook'))?_c('div',[_vm._v(" View your team's "),_c('ExternalLink',{attrs:{"href":"https://docs.prefect.io/orchestration/concepts/cloud_hooks.html"}},[_vm._v("Cloud Hooks ")])],1):_c('div',[_vm._v(" View and manage your team's "),_c('ExternalLink',{attrs:{"href":"https://docs.prefect.io/orchestration/concepts/cloud_hooks.html"}},[_vm._v(" Cloud Hooks ")])],1)]},proxy:true},(_vm.hasPermission('create', 'cloud-hook'))?{key:"cta",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onD = ref.onD;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"pb-1",staticStyle:{"display":"inline-block"}},on),[_c('v-btn',_vm._g({attrs:{"color":"primary","data-cy":"new-hook","large":""},on:{"click":function($event){_vm.createNewCloudHook = true}}},onD),[_c('v-icon',{attrs:{"left":""}},[_vm._v("cloud")]),_vm._v(" New Cloud Hook ")],1)],1)]}}],null,true)},[(!_vm.hasPermission('create', 'cloud-hook'))?_c('span',[_vm._v(" You don't have permission to create new Cloud Hooks. ")]):_c('span',[_vm._v(" Create a new Cloud Hook ")])])]}}],null,false,3092207496),model:{value:(_vm.createNewCloudHook),callback:function ($$v) {_vm.createNewCloudHook=$$v},expression:"createNewCloudHook"}},[_c('v-card',{staticClass:"pa-2",attrs:{"tile":""}},[_c('CardTitle',{attrs:{"title":"New Cloud Hook","icon":"cloud"}}),_c('v-card-text',{staticClass:"pl-12"},[(_vm.createNewCloudHook)?_c('CloudHookForm',{attrs:{"editable":_vm.hasPermission('create', 'cloud-hook') &&
                _vm.hasPermission('delete', 'cloud-hook'),"edit-on-render":""},on:{"close":function($event){_vm.createNewCloudHook = false},"update":function($event){return _vm.$apollo.queries.cloudHooks.refetch()}}}):_vm._e()],1)],1)],1)]},proxy:true}:null],null,true)},[_c('v-card',{class:{ 'mt-3': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"tile":""}},[_c('div',{staticClass:"py-1 mr-2 flex"},[_c('v-text-field',{staticClass:"rounded-0 elevation-1",style:({
          'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '420px' : null
        }),attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search by hook name or type","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-2 rounded-0",class:{ 'fixed-table': _vm.$vuetify.breakpoint.smAndUp },attrs:{"headers":_vm.allHeaders,"header-props":{ 'sort-icon': 'arrow_drop_up' },"data-cy":"cloud-hook-table","search":_vm.search,"items":_vm.cloudHooks,"items-per-page":10,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [10, 15, 20, -1],
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right'
      },"loading":!_vm.isLoadingTable,"no-data-text":"No Cloud Hooks found."},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
      var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2 text-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.active",fn:function(ref){
      var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2 text-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.version_group_id",fn:function(ref){
      var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2 text-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.type",fn:function(ref){
      var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2 text-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.states",fn:function(ref){
      var header = ref.header;
return [_vm._v("text- "),_c('span',{staticClass:"text-subtitle-2 text-center"},[_vm._v(_vm._s(header.text))])]}},{key:"item.version_group_id",fn:function(ref){
      var item = ref.item;
return [_c('ApolloQuery',{attrs:{"query":function (gql) { return gql(templateObject); },"variables":{ VGI: item.version_group_id },"skip":!item.version_group_id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ref_result = ref.result;
            var loading = ref_result.loading;
            var error = ref_result.error;
            var data = ref_result.data;
return [(loading)?_c('div',{staticClass:"loading apollo"},[_vm._v("Loading...")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v("An error occurred")]):(data && data.flow[0])?_c('div',{staticClass:"result apollo"},[_c('router-link',{attrs:{"to":{
                  name: 'flow',
                  params: {
                    id: data.flow[0].id,
                    tenant: _vm.tenant.slug
                  },
                  query: { settings: '' }
                }}},[_vm._v(" "+_vm._s(data.flow[0].name))])],1):(data)?_c('div',[_vm._v(" No flow :(")]):_c('div',{staticClass:"no-result apollo"},[_vm._v("No flow :(")])]}}],null,true)})]}},{key:"item.type",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3"},on),[_vm._v(" "+_vm._s(_vm.typeIcon(item.type))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.typeTitle(item.type))+" ")])])]}},{key:"item.active",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({on:{"click":function($event){$event.stopPropagation();}}},on),[_c('div',{staticClass:"vertical-button mr-3"},[_c('v-switch',{staticClass:"v-input--vertical mt-0",attrs:{"hide-details":"","color":"primary","loading":item.loading,"disabled":(!_vm.hasPermission('create', 'cloud-hook') &&
                      !_vm.hasPermission('delete', 'cloud-hook')) ||
                      item.loading},on:{"change":function($event){return _vm._handleSetCloudHookStatusChange($event, item)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-btn',{staticClass:"mb-1",attrs:{"tile":"","small":"","text":"","disabled":""}},[_vm._v(" "+_vm._s(item.active ? 'On' : 'Off')+" ")])]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)])]}}],null,true)},[(!_vm.hasPermission('update', 'cloud-hook'))?_c('span',[_vm._v(" You don't have permission to change Cloud Hook states. ")]):_c('span',[_vm._v(" "+_vm._s(item.active ? 'Deactivate' : 'Activate')+" this Cloud Hook. ")])])]}},{key:"item.states",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-body-2"},on),[_c('v-chip',{staticClass:"ml-2",attrs:{"label":"","small":"","dark":"","color":_vm.stateGroupColor(_vm.stateGroup(item.states))}},[_vm._v(" "+_vm._s(_vm.stateGroup(item.states))+" ")])],1)]}}],null,true)},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.formatStates(item.states)))])])]}},(
          _vm.hasPermission('create', 'cloud-hook') &&
            _vm.hasPermission('delete', 'cloud-hook')
        )?{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"display":"inline-block"}},on),[_c('v-btn',{attrs:{"loading":_vm.setTest === item.id,"text":"","fab":"","color":"warning","x-small":""},on:{"click":function($event){return _vm.testCloudHook(item)}}},[_c('v-icon',[_vm._v("bug_report")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(" Test this Cloud Hook (will send a test call to your endpoint) ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.dialogEditHook = true
                _vm.selectHook = item}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_vm._v(" Modify this cloud hook ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","text":"","fab":"","x-small":""},on:{"click":function($event){_vm.dialogDeleteHook = true
                _vm.selectHook = item}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Delete this cloud hook ")])]}}:null],null,true)}),(_vm.selectHook)?_c('ConfirmDialog',{attrs:{"type":"error","dialog-props":{ 'max-width': '600' },"disabled":_vm.removingHook,"loading":_vm.removingHook,"title":("Are you sure you want to delete " + (_vm.selectHook.name ? '' : 'this') + " cloud hook " + (_vm.selectHook.name ? _vm.selectHook.name : '') + "?")},on:{"cancel":_vm.closeHookDialog,"confirm":_vm.deleteCloudHook},model:{value:(_vm.dialogDeleteHook),callback:function ($$v) {_vm.dialogDeleteHook=$$v},expression:"dialogDeleteHook"}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.dialogEditHook),callback:function ($$v) {_vm.dialogEditHook=$$v},expression:"dialogEditHook"}},[_c('v-card',{staticClass:"pa-2",attrs:{"tile":""}},[_c('CardTitle',{attrs:{"title":"Edit Cloud Hook","icon":"cloud"}}),_c('v-card-text',{staticClass:"pl-12"},[(_vm.dialogEditHook)?_c('CloudHookForm',{attrs:{"editable":_vm.hasPermission('create', 'cloud-hook') &&
                _vm.hasPermission('delete', 'cloud-hook'),"edit-on-render":"","hook":_vm.selectHook,"show-controls":"","version-group-id-prop":_vm.selectHook.version_group_id,"loading":_vm.selectHook.loading},on:{"update:loading":function($event){return _vm.$set(_vm.selectHook, "loading", $event)},"close":function($event){_vm.dialogEditHook = false},"update":function($event){return _vm.$apollo.queries.cloudHooks.refetch()}}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }